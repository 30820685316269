import React from "react";

const StripeFailure = ({ data }) => {
  return (
    <div style={{ padding: "0 40px" }}>
      <h1>
        Creating stripe account failed, please try again later or check your
        data during registration
      </h1>
    </div>
  );
};

export default StripeFailure;
